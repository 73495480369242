import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tool from '@material-ui/core/Tooltip';

const base = {
  maxWidth: 350,
  paddingTop: 10,
  paddingLeft: 16,
  paddingRight: 16,
  paddingBottom: 10,
  borderRadius: '8px',
  textAlign: 'left',
  fontSize: '14px !important',
  fontWeight: '500',
  zIndex: '2147483647 !important'
}

const arrow = {
  fontSize: '0 !important'
}

const useStylesDefault = makeStyles(() => ({
  arrow,
  tooltip: {
    backgroundColor: '#003057 !important',
    color: '#fff !important',
    boxShadow: '0 8px 16px 0 rgba(0, 48, 87, 0.3)',
    ...base
  }
}));

const useStylesLight = makeStyles(() => ({
  arrow,
  tooltip: {
    ...base,
    backgroundColor: '#edf1f7 !important',
    color: '#003057 !important'
  }
}));

const useCustom = makeStyles(() => ({
  arrow,
  tooltip: {
    ...base,
    backgroundColor: '#fff !important',
    color: '#003057 !important',
    border: '1px solid #CCD6E3',
    boxShadow: '0 8px 16px 0 rgba(0, 48, 87, 0.3)'
  }
}));

const Tooltip = ({ children, content, position, theme, delay, enterTouchDelay, ...rest }) => {
  const defaultStyle = useStylesDefault();
  const lightStyle = useStylesLight();
  const custom = useCustom();

  const displayContent = useMemo(
    () => content === null ? '' : content,
    [content]
  );

  const initTheme = useMemo(
    () => {
      switch (theme) {
        case 'light': return lightStyle;
        case 'custom': return custom;
        case 'default': return defaultStyle;
        default: return defaultStyle;
      }
    },
    [theme, lightStyle, custom, defaultStyle]
  );

  return (
    <Tool
      {...rest}
      arrow={theme !== 'custom'}
      title={displayContent}
      placement={position}
      enterDelay={delay}
      enterTouchDelay={enterTouchDelay}
      classes={initTheme}
    >
      {children}
    </Tool>
  );
};

Tooltip.propTypes = {
  content: PropTypes.any,
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  enterTouchDelay: PropTypes.number,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  theme: PropTypes.oneOf(['default', 'light'])
};

Tooltip.defaultProps = {
  content: null,
  position: 'top',
  delay: null,
  enterTouchDelay: 100,
  theme: 'default'
};

export default memo(Tooltip);
