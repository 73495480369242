import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Switch.module.scss';

const Switch = ({
  name,
  id,
  checked,
  onChange,
  className,
  itemId,
  dataId,
  disabled
}) => {
  const cnSwitch = cn(styles.container, className, {
    [styles.disabled]: disabled
  });

  const cnLabel = cn(styles.label, {
    [styles.active]: checked,
    [styles.disabled]: disabled
  });

  const handleOff = useCallback(
    () => checked ? onChange() : null,
    [checked, onChange]
  );

  const handleOn = useCallback(
    () => !checked ? onChange() : null,
    [checked, onChange]
  );

  const styleOn = useMemo(
    () => ({ fontWeight: checked && '700' }),
    [checked]
  );

  return (
    <div className={cnSwitch}>
      <span onClick={handleOff} data-id={dataId}>OFF</span>
      <label htmlFor={id} className={cnLabel}>
        <input
          className={styles.input}
          type="checkbox"
          id={id}
          name={name}
          value={id}
          data-itemid={itemId || id}
          checked={checked}
          onChange={onChange}
          aria-checked={checked}
          aria-describedby={id}
          disabled={disabled}
          data-id={dataId}
        />
      </label>
      <span style={styleOn} onClick={handleOn} data-id={dataId}>ON</span>
    </div>
  );
};

Switch.displayName = 'Switch';

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  dataId: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

Switch.defaultProps = {
  itemId: '',
  dataId: null,
  name: null,
  className: '',
  onChange: () => {},
  disabled: false
};

export default memo(Switch);
