import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Textarea.module.scss';

const Textarea = ({
  placeholder,
  id,
  dataId,
  name,
  value,
  cols,
  rows,
  required,
  disabled,
  form,
  onChange,
  className,
  error,
  messageError,
  valid,
  helper,
  ...rest
}) => {
  const container = cn(styles.container, className, {
    [styles.hasValue]: value !== '',
    [styles.hasSuccess]: valid
  });

  const textarea = cn(styles.textarea, {
    [styles.hasError]: error
  });

  return (
    <div className={container}>
      <textarea
        {...rest}
        id={id}
        data-id={dataId || id}
        className={textarea}
        placeholder={placeholder}
        name={name}
        value={value}
        cols={cols}
        rows={rows}
        required={required}
        disabled={disabled}
        form={form}
        onChange={onChange}
        aria-multiline="true"
        aria-required={required}
      />
      {helper && <span className={styles.helper}>{helper}</span>}
      {error && <span className={styles.error}>{messageError}</span>}
    </div>
  );
};

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
  id: PropTypes.string,
  dataId: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  cols: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any
  ]),
  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.any
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Textarea.defaultProps = {
  placeholder: '',
  id: '',
  dataId: null,
  value: '',
  cols: '20',
  rows: '5',
  required: false,
  disabled: false,
  form: '',
  className: '',
  valid: false,
  error: false,
  messageError: '',
  helper: ''
};

export default memo(Textarea);
