/* eslint-disable react/prop-types */
import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import HeightTransition from '../utils/HeightTransition';
import Icon from '../Icon';

import styles from './Input.module.scss';

const addSeparator = value => (
  value && value
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
);

const Input = forwardRef((props, ref) => {
  const {
    id,
    dataId,
    className,
    type,
    placeholder,
    name,
    value,
    required,
    onChange,
    onBlur,
    onFocus,
    icon,
    size,
    helper,
    error,
    errorMessage,
    errorRequiredMessage,
    numericThousandSeparator,
    fullWidth,
    onKeyPress,
    onEnterPress,
    maxLength,
    autoComplete,
    valid,
    forceError,
    resetStyle,
    ...rest
  } = props;
  const [focus, setFocus] = useState(false);
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [formattedValue, setFormattedValue] = useState(() => addSeparator(value));

  const hasError = hasBeenFocused && !focus && error;
  const hasForceError = !focus && forceError;
  const hasRequiredError = error || (hasBeenFocused && required && !focus && !value);

  const handleDisplayError = hasError || hasRequiredError;

  const handleChange = useCallback(
    e => {
      e.persist();
      const val = e?.target?.value;
      if (onChange) {
        if (!numericThousandSeparator) {
          return onChange({
            value: val,
            name: e?.target?.name,
            id
          });
        }
        setFormattedValue(addSeparator(val));
        return onChange({
          value: val.replace(/\D+/g, ''),
          name: e?.target?.name,
          id,
          event: e
        })
      }
    },
    [id, numericThousandSeparator, onChange]
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') onEnterPress(e);
      if (onKeyPress && e.key !== 'Enter') onKeyPress(e);
    },
    [onEnterPress, onKeyPress]
  )

  const handleFocus = useCallback(
    async e => {
      e.persist();
      await setFocus(true);
      await setHasBeenFocused(true);
      onFocus({ event: e });
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    e => {
      e.persist();
      setFocus(false);
      onBlur({ event: e });
    },
    [onBlur]
  );

  const renderIcon = useMemo(
    () => {
      if (error || icon || handleDisplayError || hasForceError || valid) {
        const getLabel = (((error || handleDisplayError) && hasBeenFocused && !focus) || hasForceError)
          ? 'times'
          : (valid && hasBeenFocused)
            ? 'check'
            : icon;
        return (
          <Icon
            className={styles.icon}
            label={getLabel}
            width={size === 'big' ? 16 : 12}
            fill={error ? '#f93434' : valid ? '#27D96C' : '#003057'}
          />
        )
      }
      return null;
    },
    [hasBeenFocused, handleDisplayError, focus, error, icon, size, hasForceError, valid]
  );

  const cnContainer = cn(styles.container, styles[size], className, {
    [styles.fullWidth]: fullWidth,
    [styles.error]: handleDisplayError || error || hasForceError,
    [styles.hasIcon]: icon,
    [styles.reset]: resetStyle,
    [styles.valid]: valid
  });

  return (
    <div className={cnContainer} {...rest}>
      <div className={styles.content}>
        <input
          {...rest}
          ref={ref}
          id={id}
          placeholder={placeholder}
          className={styles.input}
          type={numericThousandSeparator ? 'text' : type}
          name={name}
          value={numericThousandSeparator ? formattedValue : value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          required={required}
          onKeyPress={handleKeyPress}
          maxLength={maxLength}
          autoComplete={autoComplete}
          data-id={dataId || id}
        />
        {renderIcon}
      </div>
      <HeightTransition config={{
        tension: 340,
        friction: 26,
        mass: 1,
        precision: 1
      }} on={(hasRequiredError && errorRequiredMessage) || ((hasError || hasForceError) && errorMessage) || false}>
        <span className={styles.helper}>
          {(hasRequiredError && errorRequiredMessage) || ((hasError || hasForceError) && errorMessage)}
        </span>
      </HeightTransition>
    </div>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  dataId: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  resetStyle: PropTypes.bool,
  numericThousandSeparator: PropTypes.bool,
  errorMessage: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'number',
    'tel',
    'search',
    'hidden'
  ]),
  size: PropTypes.oneOf([
    'huge',
    'big',
    'medium',
    'default',
    'small'
  ]),
  value: PropTypes.any,
  valid: PropTypes.bool,
  error: PropTypes.bool,
  forceError: PropTypes.bool,
  helper: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onKeyPress: PropTypes.func,
  onEnterPress: PropTypes.func,
  autoComplete: PropTypes.oneOf(['on', 'off'])
};

Input.defaultProps = {
  onChange: null,
  id: undefined,
  dataId: undefined,
  className: undefined,
  icon: null,
  name: null,
  type: 'text',
  value: undefined,
  size: 'medium',
  valid: false,
  fullWidth: false,
  resetStyle: false,
  required: false,
  numericThousandSeparator: false,
  error: false,
  forceError: false,
  errorMessage: null,
  helper: null,
  onKeyPress: () => {},
  onEnterPress: () => {},
  onFocus: () => {},
  onBlur: () => {}
};

export default memo(Input);
