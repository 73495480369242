import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../Tooltip';
import ConditionalWrapper from '../ConditionalWrapper';

import styles from './Switch2.module.scss';

const Switch2 = ({
  label,
  tooltip,
  name,
  id,
  checked,
  onChange,
  className,
  itemId,
  dataId,
  disabled
}) => {
  const cnSwitch = cn(styles.container, className, {
    [styles.disabled]: disabled
  });

  const cnLabel = cn(styles.label, {
    [styles.active]: checked,
    [styles.disabled]: disabled
  });

  const cnLabelWrapper = cn(styles.labelWrapper, {
    [styles.disabled]: disabled
  });

  return (
    <ConditionalWrapper
      wrapper={children =>
        tooltip ? <Tooltip content={tooltip}>{children}</Tooltip> : children
      }
    >
      <div className={cnSwitch}>
        <label htmlFor={id} className={cnLabel}>
          <input
            className={styles.input}
            type="checkbox"
            id={id}
            name={name}
            value={id}
            data-itemid={itemId || id}
            checked={checked}
            onChange={onChange}
            aria-checked={checked}
            aria-describedby={id}
            disabled={disabled}
            data-id={dataId || id}
          />
        </label>
        <div className={cnLabelWrapper} onClick={!disabled && onChange} data-id={dataId || id}>
          {label}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

Switch2.displayName = 'Switch2';

Switch2.propTypes = {
  id: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  dataId: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

Switch2.defaultProps = {
  itemId: '',
  dataId: null,
  name: null,
  label: '',
  tooltip: '',
  className: '',
  onChange: () => {},
  disabled: false
};

export default memo(Switch2);
