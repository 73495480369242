import React from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import cn from 'classnames';

import styles from './Sidebar.module.scss';

const Sidebar = ({ children, on, className, width }) => {
  const transitions = useTransition(on, null, {
    from: { transform: 'translate3d(100%, 0, 0)', width },
    enter: { transform: 'translate3d(0, 0, 0)', width },
    leave: { transform: 'translate3d(100%, 0, 0)', width },
    unique: true,
    config: {
      mass: 0.5,
      tension: 500,
      friction: 30
    }
  });

  const cnSidebar = cn(styles.sidebar, className);

  return transitions.map(({ item, key, props }) => (
    item && (
      <animated.div
        key={key}
        style={props}
        className={cnSidebar}
      >
        <div>{children}</div>
      </animated.div>
    )
  ))
};

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
  on: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  onClickAway: PropTypes.func,
  width: PropTypes.string
};

Sidebar.defaultProps = {
  on: false,
  children: null,
  className: null,
  onClickAway: () => {},
  width: '300px'
}

export default Sidebar;
