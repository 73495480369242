import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Date from '../Date2';
import Icon from '../Icon';
import Text from '../Text';

import styles from './ButtonDate.module.scss';

const ButtonDate = ({
  from,
  to,
  onDatesChange,
  startDate,
  endDate,
  startDatePlaceholder,
  endDatePlaceholder,
  size,
  ...props
}) => {
  const dateRef = useRef(null);

  const cnWrapper = cn(styles.wrapper, styles[size]);

  return (
    <div className={cnWrapper}>
      <Text resetMargin fontSize={14} className={styles.from}>
        {from}
      </Text>
      <Date
        {...props}
        ref={dateRef}
        noLabels
        noBorder
        startDatePlaceholder={startDatePlaceholder}
        endDatePlaceholder={endDatePlaceholder}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        minimumNights={0}
        customArrowIcon={
          <Text resetMargin fontSize={14} className={styles.to}>
            {to}
          </Text>
        }
        customInputIcon={
          <Icon
            label="caret-circle-down"
            theme="solid"
            fill="#0061AC"
            className={styles.icon}
          />
        }
        inputIconPosition="after"
      />
    </div>
  );
};

ButtonDate.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

ButtonDate.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  onDatesChange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startDatePlaceholder: PropTypes.string.isRequired,
  endDatePlaceholder: PropTypes.string.isRequired
};

ButtonDate.defaultProps = {
  from: 'From: ',
  to: 'to: ',
  size: 'medium'
};

export default memo(ButtonDate);
