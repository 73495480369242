import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ToggleButton.module.scss';

const ToggleButton = forwardRef(({ name, id, checked, onChange, className, dataId, children, disabled, value }, ref) => {
  const container = cn(styles.container, className);

  const cnLabel = cn(styles.label, {
    [styles.active]: checked,
    [styles.disabled]: disabled
  });

  return (
    <div className={container} ref={ref}>
      <label htmlFor={id} className={cnLabel}>
        <input
          id={id}
          className={styles.input}
          type="checkbox"
          name={name}
          value={value}
          data-itemid={dataId || id}
          checked={checked}
          onChange={onChange}
          aria-checked={checked}
          aria-describedby={id}
        />
        {children}
      </label>
    </div>
  );
});

ToggleButton.displayName = 'ToggleButton';

ToggleButton.propTypes = {
  id: PropTypes.string.isRequired,
  dataId: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

ToggleButton.defaultProps = {
  dataId: '',
  className: ''
};

export default memo(ToggleButton);
