import React, { Children, useCallback, useRef, useState, memo } from 'react';
import cn from 'classnames';

import Button2 from '../Button2';
import Icon from '../Icon';

import styles from './SelectButton.module.scss';

const SelectButton = ({
  id, label, onClick, dataset, onSelectChange, value, name, className, disabled, required, size, theme, ...rest
}) => {
  const [hovered, setHovered] = useState(false);

  const iconRef = useRef(null);
  const selectRef = useRef(null);

  const handleMouseEnter = useCallback(
    () => setHovered(true),
    []
  );

  const handleMouseLeave = useCallback(
    () => setHovered(false),
    []
  );

  const handleSelectChange = useCallback(
    e => onSelectChange({
      value: e.target.value,
      event: e
    }),
    [onSelectChange]
  );

  const cnSelectButton = cn(
    className,
    styles.container,
    styles[size],
    styles[theme], {
      [styles.disabled]: disabled
    }
  );

  const cnSelect = cn(styles.select, styles[theme]);
  const cnMask = cn( styles.mask, styles[theme], styles[size], {
    [styles.hovered]: hovered,
    [styles.disabled]: disabled
  });
  const cnIcon = cn(styles.icon, styles[theme], {
    [styles.disabled]: disabled
  });

  return (
    <div className={cnSelectButton}>
      <Button2
        className={styles.button}
        label={label}
        onClick={onClick}
        disabled={disabled}
        theme={theme}
        size={size}
        {...rest}
      />
      <select
        id={id}
        ref={selectRef}
        name={name}
        className={cnSelect}
        onChange={handleSelectChange}
        value={value}
        disabled={disabled}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        required={required}
      >
        {Children.toArray(dataset?.map(option => (
          <option
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        )))}
      </select>
      <div className={cnMask} />
      <Icon
        ref={iconRef}
        id={id}
        className={cnIcon}
        label="chevron-up"
        theme="regular"
        width={12}
      />
    </div>
  );
};

SelectButton.defaultProps = {
  onSelectChange: () => {},
  size: 'small'
}

export default memo(SelectButton);
