import React from 'react';
import { ReactComponent as Campaign } from './campaign.svg';
import { ReactComponent as Choose } from './choose_campaign.svg';
import { ReactComponent as Irm } from './IRM.svg';
import { ReactComponent as Listening } from './listening.svg';
import { ReactComponent as Message } from './message.svg';
import { ReactComponent as Reporting } from './reporting.svg';
import { ReactComponent as Search } from './search.svg';

export default () => ({
  'folders': <Campaign />,
  'folder-tree': <Choose />,
  'users': <Irm />,
  'chart-pie': <Listening />,
  'comments': <Message />,
  'chart-user': <Reporting />,
  'telescope': <Search />,
  undefined: <Campaign />
});
