import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../Icon';
import Input from '../Input';
import Tooltip from '../Tooltip';

import styles from './InputButton.module.scss';

const InputButton = ({ onClick, label, width, open, alert, tooltipAlert, dataId }) => {
  const styleInputButton = useMemo(
    () => ({ width }),
    [width]
  );

  const cnInputButton = cn(styles.inputButton, {
    [styles.rotate]: open,
    [styles.alert]: alert
  });

  const displayAlert = useMemo(
    () => alert && !open ? (
      <span className={styles.icon}>
        <Icon
          label="exclamation-circle"
          tooltip={tooltipAlert}
          fill="#fc3950"
        />
      </span>
    ) : null,
    [alert, open, tooltipAlert]
  )

  return (
    <div onClick={onClick} data-id={dataId} role="button">
      <Tooltip content={label}>
        <Input
          className={cnInputButton}
          style={styleInputButton}
          value={label}
          icon="chevron-down"
          onChange={null}
          readOnly
          dataId={dataId ? `${dataId}-input` : null}
        />
      </Tooltip>
      {displayAlert}
    </div>
  )
};

InputButton.displayName = 'InputButton';

Input.propTypes = {
  alert: PropTypes.bool,
  dataId: PropTypes.string,
  width: PropTypes.number
}

InputButton.defaultProps = {
  width: 200,
  dataId: null,
  alert: false
}

export default memo(InputButton);
