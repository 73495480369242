import React, { memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import cn from 'classnames';

import Icon from '../Icon';
import Text from '../Text';
import Portal from '../Portal';
import styles from './SnackBar.module.scss';

const SnackBar = ({ title, body, on, className, timeout, onClick, status, ...rest }) => {
  const displaySnackBar = useTransition(on, null, {
    config: { tension: 500, friction: 35, mass: 1, precision: 0.00001 },
    from: { transform: 'translateX(120%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(120%)' },
    unique: true
  });

  const handleClick = useCallback(
    () => onClick(),
    [onClick]
  );

  const timer = useMemo(
    () => setTimeout(handleClick, timeout),
    [handleClick, timeout]
  );

  useEffect(() => {
    (() => {
      if (!timeout && on) {
        clearTimeout(timeout);
        return timer;
      }
    })();
    return () => clearTimeout(timeout);
  }, [timeout, on, timer]);

  const getFillStatus = ({
    'default': '#fff',
    'success': '#02C39A',
    'error': '#F22254'
  })

  const cnSnackBar = cn(
    styles.snackbar,
    className,
    styles[status]
  );

  return (
    <Portal>
      {displaySnackBar.map(({ item, key, props }) => item &&
        <animated.div
          key={key}
          className={cnSnackBar}
          role="contentinfo"
          style={props}
          {...rest}
        >
          <Icon
            className={styles.iconCheck}
            label="check-circle"
            theme="solid"
            size="huge"
            fill={getFillStatus?.[status]}
          />
          <div>
            <Text className={styles.title}>{title}</Text>
            <Text className={styles.body}>{body}</Text>
          </div>
          <Icon
            className={styles.iconClose}
            label="times"
            onClick={handleClick}
            isButton
            size="big"
            theme="regular"
            fill="#fff"
          />
        </animated.div>)}
    </Portal>
  );
};

SnackBar.displayName = 'SnackBar';

SnackBar.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  timeout: PropTypes.number,
  status: PropTypes.oneOf(['default', 'success', 'error'])
};

SnackBar.defaultProps = {
  className: '',
  timeout: 70000,
  status: 'default'
};

export default memo(SnackBar);
