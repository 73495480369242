import React, { cloneElement, forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Tooltip from '../Tooltip';

import brand from './Icons/brand';
import regular from './Icons/regular';
import solid from './Icons/solid';
import thin from './Icons/thin';
import duotone from './Icons/duotone';
import navbar from './Icons/navbar';

import styles from './Icon.module.scss';

const sizeIcon = {
  tiny: {
    container: 22,
    icon: 10
  },
  small: {
    container: 22,
    icon: 14
  },
  medium: {
    container: 32,
    icon: 16
  },
  big: {
    container: 40,
    icon: 20
  },
  huge: {
    container: 40,
    icon: 24
  }
}

const Icon = forwardRef(({
  id,
  onClick,
  onKeyPress,
  isButton,
  className,
  label,
  width,
  style,
  theme,
  fill,
  size,
  disabled,
  active,
  hovered,
  activeFill,
  tooltip,
  tooltipPosition,
  dataId,
  ...rest
}, ref) => {
  const cnIcon = cn(styles.icon, className, {
    [styles.isButton]: isButton,
    [styles.hovered]: hovered,
    [styles.disabled]: disabled,
    [styles.alignComment]: label === 'comment-alt' || label === 'comment-alt-plus',
    [styles.alignTag]: label === 'tag'
  });

  const containerStyle = useMemo(
    () => {
      const initSize = !isButton && width
        ? width
        : isButton
          ? sizeIcon[size].container
          : sizeIcon[size].icon;
      return {
        width: initSize,
        height: initSize,
        flex: `0 0 ${initSize}px`,
        ...style
      }
    },
    [isButton, size, width, style]
  );

  const getBrandColor = useMemo(
    () => {
      switch (label) {
        case 'instagram-color': return '#AC35A9';
        case 'facebook-color': return '#3B5998';
        case 'tiktok-color': return '#010101';
        case 'twitter-color': return '#1DA1F2';
        case 'youtube-color': return '#E62117';
        default: return fill;
      }
    },
    [fill, label]
  );

  const IconStyle = useMemo(
    () => {
      const initSize = (!isButton && width) ? width : sizeIcon[size].icon;
      return {
        fill: !active ? getBrandColor : activeFill,
        width: initSize,
        height: initSize,
        opacity: disabled ? '0.4' : '1'
      }
    },
    [isButton, size, width, disabled, getBrandColor, active, activeFill]
  );

  const getTheme = useMemo(
    () => ({
      'navbar': navbar(),
      'brand': brand(),
      'regular': regular(),
      'solid': solid(),
      'thin': thin(),
      'duotone': duotone()
    }),
    []
  );

  const getIcon = useMemo(
    () => {
      const keys = Object.keys(getTheme[theme]).map(key => key);
      if (keys.includes(label)) {
        return getTheme[theme][label];
      }
      return thin()?.['info'];
    },
    [getTheme, theme, label]
  );

  const renderIcon = () => label && (
    <span
      id={id}
      ref={ref}
      className={cnIcon}
      style={containerStyle}
      onClick={disabled ? null : onClick}
      data-id={dataId || id}
      onKeyPress={onKeyPress}
      role={onClick ? 'button' : 'img'}
      {...rest}
    >
      {cloneElement(
        !active
          ? getIcon
          : solid()[label],
        {
          id,
          className: styles.svg,
          width,
          height: width,
          style: IconStyle
        }
      )}
    </span>
  );

  return !tooltip ? renderIcon() : (
    <Tooltip content={tooltip} position={tooltipPosition}>
      {renderIcon()}
    </Tooltip>
  )
});

Icon.displayName = 'Icon';

Icon.propTypes = {
  id: PropTypes.string,
  dataId: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  fill: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  style: PropTypes.shape({}),
  theme: PropTypes.oneOf(['thin', 'solid', 'regular', 'brand', 'duotone']),
  size: PropTypes.oneOf(['huge', 'big', 'medium', 'small', 'tiny']),
  label: PropTypes.string,
  isButton: PropTypes.bool,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.any,
  tooltipPosition: PropTypes.string,
  activeFill: PropTypes.string
};

Icon.defaultProps = {
  onClick: () => {},
  onKeyPress: () => {},
  className: undefined,
  style: undefined,
  fill: '#003057',
  width: null, // should only be used to overwrite "size"
  theme: 'thin',
  size: 'medium',
  isButton: false,
  hovered: false,
  disabled: false,
  label: null,
  active: false,
  dataId: null,
  activeFill: '#1e5c90'
};

export default memo(Icon);
