/* eslint-disable no-unused-expressions,react/prop-types */
import React, { PureComponent } from 'react';
import cn from 'classnames';

import Icon from '../Icon';
import Input from '../Input';

import styles from './InputTag.module.scss';

class SearchBarTag extends PureComponent {
  state = {
    isEdited: false,
    tagValue: '',
    widthEditionMode: 0
  }

  componentDidUpdate = prevProps => {
    const { isSelected, myRef } = this.props;
    const { isEdited } = this.state;

    if (!prevProps.isSelected && isSelected) {
      window.addEventListener('keydown', this.onKeyDownSelectedTag);
    }
    if (prevProps.isSelected && !isSelected) {
      window.removeEventListener('keydown', this.onKeyDownSelectedTag);
    }
    if (isEdited) {
      myRef?.current.querySelector('input').focus();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.onKeyDownSelectedTag);
  }

  onKeyDownSelectedTag = e => {
    const { deleteTag, onArrowKey, onKeyDownSelectedTagActive, myRef, activateOnKeyDownSelectedTag } = this.props;
    const { key } = e;

    if (onKeyDownSelectedTagActive) {
      if (key === 'ArrowLeft') { onArrowKey('left') }
      if (key === 'ArrowRight') { onArrowKey('right') }
    } else {
      activateOnKeyDownSelectedTag()
    }
    if (key !== 'Delete' && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') return null;
    if (key === 'Delete' || key === 'Backspace') {
      deleteTag();
    } else if (key === 'Enter') {
      this.activateEditionMode();
    } else {
      e.preventDefault();
      myRef?.current.querySelector('input').focus();
    }
  }

  onBlurSelectedTag = () => {
    const { index, onChangeSelectedTag } = this.props;
    onChangeSelectedTag({index, value: false});
    window.removeEventListener('keydown', this.onKeyDownSelectedTag);
  }

  selectTag = () => {
    const { selectable, onChangeSelectedTag, index } = this.props;
    if (!selectable) return null;
    onChangeSelectedTag({ index, value: true });
  }

  editTag = ({ value: tagValue }) => {
    const comma = tagValue.indexOf(',');
    if (comma > -1) {
      if (tagValue.trim().length === 1) return null;
      this.updateTag()
    }
    this.setState({ tagValue });
  }

  activateEditionMode = () => {
    const { editable, value, index, myRef } = this.props;

    if (!editable) return null;

    const el = myRef?.current.querySelectorAll('.search-bar-tag')?.[index].offsetWidth;
    window.removeEventListener('keydown', this.onKeyDownSelectedTag);
    this.setState({ isEdited: true, tagValue: value, widthEditionMode: el + 8 });
  }

  renderPrefixedTag = () => {
    const { value: tag } = this.props;
    const colorTag = tag.substr(0, 1);
    const simpleTag = tag.substr(1, tag?.length - 1);

    return (
      <button
        className={styles.tagInner}
        onClick={this.selectTag}
        onDoubleClick={this.activateEditionMode}
        onBlur={this.onBlurSelectedTag}
      >
        <span className="search-bar-tag-inner--colored">
          {colorTag}
        </span>
        {simpleTag}
      </button>
    )
  }

  renderWrappedTag = () => {
    const { value: tag } = this.props;
    const colorTag = tag.substr(0, 1);
    const simpleTag = tag.substr(1, tag?.length - 2);

    return (
      <button
        className="search-bar-tag-inner"
        onClick={this.selectTag}
        onDoubleClick={this.activateEditionMode}
        onBlur={this.onBlurSelectedTag}
      >
        <span className="search-bar-tag-inner--colored">
          {colorTag}
        </span>
        {simpleTag}
        <span className="search-bar-tag-inner--colored search-bar-tag-inner--colored-right-quote">
          {colorTag}
        </span>
      </button>
    )
  }

  renderHighlightedWordTag = searchHighlightWord => {
    const { value: tag } = this.props;
    const colorTag = tag?.substr(0, searchHighlightWord?.length + 1);
    const simpleTag = tag?.substr(searchHighlightWord?.length + 1, tag?.length);
    return (
      <button
        className="search-bar-tag-inner"
        onClick={this.selectTag}
        onDoubleClick={this.activateEditionMode}
        onBlur={this.onBlurSelectedTag}
      >
        <span className="search-bar-tag-inner--colored">
          {colorTag}
        </span>
        {simpleTag}
      </button>
    )
  }

  renderTag = () => {
    const { value: tag, dataId } = this.props;
    const prefixer = ['+', '-', '@', '#'];
    const prefixIndex = prefixer?.indexOf(tag[0]);
    if (prefixIndex > -1) {
      return (
        this.renderPrefixedTag()
      )
    }

    const wrapper = ['"'];
    const prefixWrapper = wrapper.indexOf(tag[0]);
    if (prefixWrapper > -1 && tag[0] === tag[tag.length - 1]) {
      return (
        this.renderWrappedTag()
      )
    }

    const { highlightWords } = this.props;
    const searchHighlightWord = highlightWords.find(word => tag.indexOf(`${word}:`) === 0);
    if (searchHighlightWord) {
      return this.renderHighlightedWordTag(searchHighlightWord)
    }

    return (
      <button
        onClick={this.selectTag}
        onBlur={this.onBlurSelectedTag}
        onDoubleClick={this.activateEditionMode}
        data-id={dataId}
      >
        {tag}
      </button>
    );
  }

  updateTag = () => {
    const { updateTag, onlyHashtagOrAt, value, deleteTag, onChangeSelectedTag, myRef, index } = this.props;
    const { tagValue } = this.state;

    if (onlyHashtagOrAt && tagValue[0] !== '@' && tagValue[0] !== '#') return null;
    if (tagValue.trim() === '') deleteTag();
    else if (tagValue !== value) updateTag(tagValue);
    this.setState({ isEdited: false });
    onChangeSelectedTag({ index, value: false });
    myRef?.current.querySelector('input').focus();
  }

  onKeyPressEditTag = ({ key, event }) => {
    if (key === 'Escape') this.setState({ isEdited: false })
    if (key === 'Tab') {
      event.preventDefault();
      this.updateTag();
    }
  }

  onBlurEditTag = () => {
    this.updateTag()
  }

  render() {
    const { disabled, deleteTag, isSelected, messaging, picture } = this.props;
    const { isEdited, tagValue, widthEditionMode } = this.state;

    const cnTags = cn('search-bar-tag', styles.tagContent, {
      'search-bar-tag--clicked': isSelected,
      'search-bar-tag--disabled': disabled,
      [styles.messaging]: messaging
    });

    return (
      <div className="seach-bar-tag-container">
        {!isEdited && (
          <div className={cnTags}>
            {messaging && (
              <div className={styles.picture}>
                <img src={picture} alt="" />
              </div>
            )}
            {this.renderTag()}
            {!disabled && (
              <Icon
                className="tags_close"
                label="times"
                onClick={deleteTag}
                width={12}
                fill={!messaging && '#fff'}
                size="small"
                isButton
              />
            )}
          </div>
        )}
        {isEdited && (
          <div className={styles.kolkit_tags_edition} style={{ width: `${widthEditionMode}px` }}>
            <Input
              resestStyle
              placeholder=""
              value={tagValue}
              onChange={this.editTag}
              onEnterPress={this.updateTag}
              onBlur={this.onBlurEditTag}
              onKeyPress={this.onKeyPressEditTag}
            />
          </div>
        )}
      </div>
    )
  }
}

export default SearchBarTag;
