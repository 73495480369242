import 'react-dates/initialize';
import React, { memo, useCallback, useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { nanoid } from 'nanoid';

import Icon from '../Icon';
import Label from '../Label';

import 'react-dates/lib/css/_datepicker.css';
import './date2.scss';
import styles from './Date2.module.scss';

const addCustomClass = () => {
  const elements = document.querySelectorAll('.DateRangePicker');
  return elements && Array.from(elements).map(element => element.classList.add('kolkit_date2'))
}

const Date2 = forwardRef((props, ref) => {
  const {
    id, date, appendToBody, className, onDatesChange, onDateChange, singleDate, dateLabel, dateLabelIcon, noBorder, theme, customArrowIcon,
    startDate, endDate, startDateLabel, endDateLabel, startDateLabelIcon, endDateLabelIcon, isOutsideRange, renderMonthElement,
    disabled, size, displayFormat, startDatePlaceholder, endDatePlaceholder, startDateId, endDateId, required, startRequired, endRequired, noLabels,
    ...rest
  } = props;
  const [focusedInput, setFocusedInput] = useState(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    addCustomClass()
  }, [])

  const handleFocusChange = useCallback(
    focusedInput => setFocusedInput(focusedInput),
    []
  );

  const handleSingleFocusChange = useCallback(
    ({ focused }) => setFocused(focused),
    []
  );

  const cnDate = cn(styles.date2, styles[size], 'kolkit_date2_wrapper', `kolkit_date2_${size}`, className, {
    [styles.singleDate]: singleDate,
    [styles.noBorder]: noBorder,
    'kolkit_date2_noBorder': noBorder,
  });

  if (theme === 'button') return (
    <div className={cnDate}>
      <DateRangePicker
        {...props}
        ref={ref}
        startDateId={startDateId}
        endDateId={endDateId}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        onFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        disabled={disabled}
        firstDayOfWeek={1}
        isOutsideRange={isOutsideRange}
        startDatePlaceholderText={startDatePlaceholder}
        endDatePlaceholderText={endDatePlaceholder}
        displayFormat={displayFormat}
        hideKeyboardShortcutsPanel
        customArrowIcon={<></>}
        noBorder
        navPrev={
          <div className={styles.left}>
            <Icon label="arrow-left" theme="regular" isButton />
          </div>
        }
        navNext={
          <div className={styles.right}>
            <Icon label="arrow-right" theme="regular" isButton />
          </div>
        }
        appendToBody={appendToBody}
      />
    </div>
  );

  return singleDate ? (
    <div className={cnDate} {...rest}>
      <Label className={styles.label} label={dateLabel} icon={dateLabelIcon} required={required} />
      <SingleDatePicker
        {...props}
        id={id}
        date={date}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={handleSingleFocusChange}
        numberOfMonths={1}
        displayFormat={displayFormat}
        noBorder
        navPrev={
          <div className={styles.left}>
            <Icon label="arrow-left" theme="regular" isButton />
          </div>
        }
        navNext={
          <div className={styles.right}>
            <Icon label="arrow-right" theme="regular" isButton />
          </div>
        }
      />
    </div>
  ) : (
    <div className={cnDate} {...rest}>
      {!noLabels && (
        <div className={styles.labels}>
          <Label className={styles.label} label={startDateLabel} icon={startDateLabelIcon} required={startRequired} />
          <Label className={styles.label} label={endDateLabel} icon={endDateLabelIcon} required={endRequired} />
        </div>
      )}
      <DateRangePicker
        {...props}
        ref={ref}
        startDateId={startDateId}
        endDateId={endDateId}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        onFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        disabled={disabled}
        firstDayOfWeek={1}
        isOutsideRange={isOutsideRange}
        startDatePlaceholderText={startDatePlaceholder}
        endDatePlaceholderText={endDatePlaceholder}
        displayFormat={displayFormat}
        hideKeyboardShortcutsPanel
        customArrowIcon={customArrowIcon || <></>}
        noBorder
        block
        navPrev={
          <div className={styles.left}>
            <Icon label="arrow-left" theme="regular" isButton />
          </div>
        }
        navNext={
          <div className={styles.right}>
            <Icon label="arrow-right" theme="regular" isButton />
          </div>
        }
        appendToBody={appendToBody}
      />
    </div>
  )
});

Date2.displayName = 'Date2';

Date2.propTypes = {
  appendToBody: PropTypes.bool,
  id: PropTypes.string,
  theme: PropTypes.string,
  customArrowIcon: PropTypes.element,
  className: PropTypes.string,
  date: PropTypes.string,
  dateLabel: PropTypes.string,
  dateLabelIcon: PropTypes.string,
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  startDateLabelIcon: PropTypes.string,
  endDateLabelIcon: PropTypes.string,
  onDatesChange: PropTypes.func,
  onDateChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  startRequired: PropTypes.bool,
  endRequired: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  displayFormat: PropTypes.any,
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
  singleDate: PropTypes.bool,
  noLabels: PropTypes.bool,
  noBorder: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  renderMonthElement: PropTypes.func
};

Date2.defaultProps = {
  id: nanoid(),
  appendToBody: false,
  customArrowIcon: null,
  className: null,
  date: null,
  dateLabel: null,
  dateLabelIcon: null,
  onDatesChange: () => {},
  onDateChange: () => {},
  startDateLabel: null,
  endDateLabel: null,
  startDateLabelIcon: null,
  endDateLabelIcon: null,
  disabled: false,
  required: false,
  startRequired: false,
  endRequired: false,
  size: 'medium',
  displayFormat: 'DD/MM/YYYY',
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  startDateId: nanoid(),
  endDateId: nanoid(),
  singleDate: false,
  noLabels: false,
  noBorder: false,
  isOutsideRange: () => false,
  renderMonthElement: null
}

export default memo(Date2);
