import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import TruncateText from '../TruncateText';

import styles from './Button2.module.scss';

/**
 * This is a Button using the new design system specs
 *
 * (zeroheight)[https://zeroheight.com/406b3c5ec/p/3903e5-button/b/32e1a2]
 *
 * ### Usage
 * ```jsx
 * <Button2 label="Click me" />
 * ```
 */
export const Button2 = React.forwardRef((
  {
    children,
    size,
    theme,
    icon,
    disabled,
    label,
    type,
    reverse,
    onClick,
    className,
    iconTheme,
    iconActive,
    iconClassName,
    iconOnClick,
    iconFill,
    iconActiveFill,
    iconSize,
    iconRotate,
    width,
    to,
    tooltip,
    fullWidth,
    isDropdown,
    active,
    dataId,
    ...rest
  }, ref) => {
  const classNames = cn(
    styles.button,
    styles[size],
    styles[theme],
    {
      [styles.withIcon]: icon && (label || children),
      [styles.reverse]: reverse,
      [styles.onlyIcon]: !children && !label,
      [styles.fullWidth]: fullWidth && !isDropdown,
      // [styles.isDropdown]: isDropdown,
      [styles.disabled]: disabled,
      [styles.active]: active
    },
    className
  );

  const buttonStyle = useMemo(
    () => ({
      ...rest.style,
      width: !width ? 'auto' : width,
      userSelect: 'none',
      pointerEvents: disabled ? 'none' : 'initial'
    }),
    [disabled, width, rest.style]
  );

  const renderIcon = useMemo(
    () => {
      const cnIcon = cn(styles.icon, iconClassName, {
        [styles.disabled]: disabled,
        [styles.rotate]: iconRotate
      });

      const handleIconClick = e => {
        if (iconOnClick) {
          e.stopPropagation();
          e.persist();
          iconOnClick(e);
        }
      }

      return icon && (
        <Icon
          label={icon}
          className={cnIcon}
          theme={iconTheme}
          active={iconActive}
          fill={iconFill}
          activeFill={iconActiveFill}
          size={iconSize}
          onClick={handleIconClick}
          dataId={dataId ? `${dataId}-icon` : null}
        />
      )
    },
    [dataId, icon, iconActive, iconTheme, iconFill, iconActiveFill, iconSize, iconRotate, iconClassName, iconOnClick, disabled]
  );

  const hasLink = useMemo(
    () => to
      ? (
        <Link to={to}>
          {children}
        </Link>
      )
      : children,
    [children, to]
  );

  const getLabel = useMemo(
    () => typeof label === 'object'
      ? label
      : (
        <TruncateText>
          {label}
        </TruncateText>
      ),
    [label]
  );

  const button = useMemo(
    () => {
      return (
        <button
          ref={ref}
          style={buttonStyle}
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={classNames}
          data-id={dataId}
          {...rest}
        >
          {renderIcon}
          {(children || label) && (
            <span className={styles.content}>
              {children && hasLink}
              {label && getLabel}
            </span>
          )}
        </button>
      )
    },
    [dataId, disabled, children, label, getLabel, onClick, type, ref, rest, classNames, renderIcon, hasLink, buttonStyle]
  )

  if (tooltip || (tooltip && disabled)) {
    return (
      <Tooltip content={tooltip}>
        <span className={styles.spanWrapper}>
          {button}
        </span>
      </Tooltip>
    );
  }

  return button;
});

Button2.displayName = 'Button2';

Button2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'tertiaryReverse', 'shopify', 'link']),
  iconTheme: PropTypes.oneOf(['solid', 'thin', 'regular']),
  iconSize: PropTypes.oneOf(['huge', 'big', 'medium', 'small']),
  icon: PropTypes.string,
  iconFill: PropTypes.string,
  iconActiveFill: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  iconActive: PropTypes.bool,
  iconRotate: PropTypes.bool,
  type: PropTypes.string,
  reverse: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isDropdown: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconOnClick: PropTypes.func,
  width: PropTypes.string,
  to: PropTypes.string,
  tooltip: PropTypes.string,
  active: PropTypes.bool,
  dataId: PropTypes.string
};

Button2.defaultProps = {
  children: null,
  icon: null,
  label: null,
  size: 'medium',
  iconTheme: 'regular',
  iconActive: false,
  iconFill: null,
  iconRotate: false,
  iconActiveFill: null,
  fullWidth: false,
  isDropdown: false,
  theme: 'primary',
  className: null,
  iconClassName: null,
  width: null,
  to: null,
  tooltip: null,
  iconOnClick: null,
  active: false,
  dataId: null
};

export default memo(Button2);
