import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../Tooltip';

import styles from './Truncate.module.scss';

const Truncate = ({ children, className }) => {
  const [tooltip, setTooltip] = useState(false);
  const ref = useRef(null);
  const childRef = useRef(null);

  const cnSpan = cn(styles.span, className);

  useEffect(() => {
    const currentParent = ref.current;
    const currentChild = childRef.current;
    if (currentParent && currentChild) {
      setTooltip(currentParent?.scrollWidth < currentChild?.scrollWidth);
    }
  }, [ref, childRef]);

  const child = useMemo(
    () => (
      <span className={cnSpan} ref={ref}>
        <span className={styles.child} ref={childRef}>
          {children}
        </span>
      </span>
    ),
    [children, childRef, cnSpan]
  )

  return tooltip
    ? <Tooltip content={children}>{child}</Tooltip>
    : child;
};

Truncate.displayName = 'Truncate';

Truncate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

Truncate.defaultProps = {
  children: null,
  className: ''
};

export default memo(Truncate);
