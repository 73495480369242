import React from 'react';
import { ReactComponent as Abacus } from './abacus.svg';
import { ReactComponent as Acorn } from './acorn.svg';
import { ReactComponent as Ad } from './ad.svg';
import { ReactComponent as At } from './at.svg';
import { ReactComponent as AddressBook } from './address-book.svg';
import { ReactComponent as AddressCard } from './address-card.svg';
import { ReactComponent as AlarmClock } from './alarm-clock.svg';
import { ReactComponent as Archive } from './archive.svg';
import { ReactComponent as AtomAlt } from './atom-alt.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as Arrows } from './arrows.svg';
import { ReactComponent as ArrowCircleLeft } from './arrow-circle-left.svg';
import { ReactComponent as ArrowCircleRight } from './arrow-circle-right.svg';
import { ReactComponent as ArrowCircleUp } from './arrow-circle-up.svg';
import { ReactComponent as ArrowCircleDown } from './arrow-circle-down.svg';
import { ReactComponent as ArrowRightFromBrackek } from './arrow-right-from-bracket.svg';
import { ReactComponent as ArrowToBottom } from './arrow-to-bottom.svg';
import { ReactComponent as Asterix } from './asterisk.svg';

import { ReactComponent as Backward } from './backward.svg';
import { ReactComponent as Badge } from './badge.svg';
import { ReactComponent as BadgePercent } from './badge-percent.svg';
import { ReactComponent as Ban } from './ban.svg';
import { ReactComponent as BarsStraggered } from './stream.svg';
import { ReactComponent as Bars } from './bars.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Bells } from './bells.svg';
import { ReactComponent as BezierCurve } from './bezier-curve.svg';
import { ReactComponent as Book } from './book.svg';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as Bullhorn } from './bullhorn.svg';
import { ReactComponent as Bullseye } from './bullseye.svg';
import { ReactComponent as BullseyePointer } from './bullseye-pointer.svg';

import { ReactComponent as ChartPie } from './chart-pie.svg';
import { ReactComponent as ChartSimple } from './chart-simple.svg';
import { ReactComponent as ChartUser } from './chart-user.svg';
import { ReactComponent as ChartWaterfall } from './chart-waterfall.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as CheckCircle } from './check-circle.svg';
import { ReactComponent as ChevronDown } from './chevron-down.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as ChevronRight } from './chevron-right.svg';
import { ReactComponent as ChevronUp } from './chevron-up.svg';
import { ReactComponent as ChevronDoubleUp } from './chevron-double-up.svg';
import { ReactComponent as ChevronDoubleRight } from './chevron-double-right.svg';
import { ReactComponent as ChevronDoubleLeft } from './chevron-double-left.svg';
import { ReactComponent as ChevronDoubleDown } from './chevron-double-down.svg';
import { ReactComponent as ChevronCircleDown } from './chevron-circle-down.svg';
import { ReactComponent as CaretCircleDown } from './caret-circle-down.svg';
import { ReactComponent as CaretDown } from './caret-down.svg';
import { ReactComponent as CaretUp } from './caret-up.svg';
import { ReactComponent as Circle } from './circle.svg';
import { ReactComponent as CircleNotch } from './circle-notch.svg';
import { ReactComponent as CirclePlus } from './circle-plus.svg';
import { ReactComponent as Clone } from './clone.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as CloudUpload } from './cloud-upload.svg';
import { ReactComponent as CodeBranch } from './code-branch.svg';
import { ReactComponent as Cog } from './cog.svg';
import { ReactComponent as Columns } from './columns.svg';
import { ReactComponent as Comment } from './comment.svg';
import { ReactComponent as Comments } from './comments.svg';
import { ReactComponent as CommentAlt } from './comment-alt.svg';
import { ReactComponent as CommentsAlt } from './comments-alt.svg';
import { ReactComponent as CommentAltPlus } from './comment-alt-plus.svg';
import { ReactComponent as CommentAltSlash } from './comment-alt-slash.svg';
import { ReactComponent as CommentLines } from './comment-lines.svg';
import { ReactComponent as Cross } from './cross.svg';

import { ReactComponent as Dizzy } from './dizzy.svg';
import { ReactComponent as Download } from './download.svg';

import { ReactComponent as InstaShare } from './insta-share.svg';

import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as EngineWarning } from './engine-warning.svg';
import { ReactComponent as Envelope } from './envelope.svg';
import { ReactComponent as EllipsisH } from './ellipsis-h.svg';
import { ReactComponent as EllipsisV } from './ellipsis-v.svg';
import { ReactComponent as EmptySet } from './empty-set.svg';
import { ReactComponent as Equals } from './equals.svg';
import { ReactComponent as Exclamation } from './exclamation.svg';
import { ReactComponent as ExclamationCircle } from './exclamation-circle.svg';
import { ReactComponent as ExclamationSquare } from './exclamation-square.svg';
import { ReactComponent as ExclamationTriangle } from './exclamation-triangle.svg';
import { ReactComponent as ExternalLink } from './external-link.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as EyeSlash } from './eye-slash.svg';
import { ReactComponent as ExternalLinkAlt } from './external-link-alt.svg';

import { ReactComponent as FileAlt } from './file-alt.svg';
import { ReactComponent as FileDownload } from './file-download.svg';
import { ReactComponent as FileImport } from './file-import.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Flask } from './flask.svg';
import { ReactComponent as FlaskPoison } from './flask-poison.svg';
import { ReactComponent as FlaskPotion } from './flask-potion.svg';
import { ReactComponent as Folder } from './folder.svg';
import { ReactComponent as Folders } from './folders.svg';
import { ReactComponent as FolderPlus } from './folder-plus.svg';
import { ReactComponent as FolderOpen } from './folder-open.svg';
import { ReactComponent as FolderTree } from './folder-tree.svg';
import { ReactComponent as Font } from './font.svg';
import { ReactComponent as Forward } from './forward.svg';

import { ReactComponent as Gauge } from './gauge.svg';
import { ReactComponent as GaugeSimple } from './gauge-simple.svg';
import { ReactComponent as GasPumpSlash } from './gas-pump-slash.svg';
import { ReactComponent as GasPump } from './gas-pump.svg';
import { ReactComponent as Gift } from './gift.svg';
import { ReactComponent as Globe } from './globe.svg';

import { ReactComponent as HandPointer } from './hand-pointer.svg';
import { ReactComponent as Handshake } from './handshake.svg';
import { ReactComponent as Hashtag } from './hashtag.svg';
import { ReactComponent as Headway } from './headway.svg';
import { ReactComponent as Heart } from './heart.svg';
import { ReactComponent as HorizontalRules } from './ruler-horizontal.svg';

import { ReactComponent as Info } from './info.svg';
import { ReactComponent as InfoCircle } from './info-circle.svg';
import { ReactComponent as InputText } from './input-text.svg';

import { ReactComponent as Link } from './link.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as LongArrowDown } from './long-arrow-down.svg';
import { ReactComponent as LongArrowLeft } from './long-arrow-left.svg';
import { ReactComponent as LongArrowRight } from './long-arrow-right.svg';
import { ReactComponent as LongArrowUp } from './long-arrow-up.svg';

import { ReactComponent as MapMarkedAlt } from './map-marked-alt.svg';
import { ReactComponent as MapMarkerAlt } from './map-marker-alt.svg';
import { ReactComponent as Mars } from './mars.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as Mobile } from './mobile.svg';

import { ReactComponent as Paperclip } from './paperclip.svg';
import { ReactComponent as Paperplane } from './paper-plane.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as PauseCircle } from './pause-circle.svg';
import { ReactComponent as Pen } from './pen.svg';
import { ReactComponent as Pennant } from './pennant.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as PlayCircle } from './play-circle.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as PowerOff } from './power-off.svg';
import { ReactComponent as QuestionCircle } from './question-circle.svg';

import { ReactComponent as Save } from './save.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as ShareAlt } from './share-alt.svg';
import { ReactComponent as ShieldCheck } from './shield-check.svg';
import { ReactComponent as SliderHSquare } from './sliders-h-square.svg';
import { ReactComponent as Smile } from './smile.svg';
import { ReactComponent as SortDown } from './sort-down.svg';
import { ReactComponent as SortUp } from './sort-up.svg';
import { ReactComponent as Sparkles } from './sparkles.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as StickyNote } from './sticky-note.svg';
import { ReactComponent as Sync } from './sync.svg';

import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as Tasks } from './tasks.svg';
import { ReactComponent as Telescope } from './telescope.svg';
import { ReactComponent as ThumbsDown } from './thumbs-down.svg';
import { ReactComponent as ThumbsUp } from './thumbs-up.svg';
import { ReactComponent as Times } from './times.svg';
import { ReactComponent as TimesCircle } from './times-circle.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as TrashAlt } from './trash-alt.svg';
import { ReactComponent as TrashList } from './trash-list.svg';

import { ReactComponent as Retweet } from './retweet.svg';

import { ReactComponent as Unlink } from './unlink.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as UserCircle } from './user-circle.svg';
import { ReactComponent as UserCog } from './user-cog.svg';
import { ReactComponent as UserGraduate } from './user-graduate.svg';
import { ReactComponent as UserPlus } from './user-plus.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Venus } from './venus.svg';
import { ReactComponent as Wrench } from './wrench.svg';

export default () => ({
  'abacus': <Abacus />,
  'acorn': <Acorn />,
  'ad': <Ad />,
  'at': <At />,
  'address-book': <AddressBook />,
  'address-card': <AddressCard />,
  'alarm-clock': <AlarmClock />,
  'archive': <Archive />,

  'arrow-left': <ArrowLeft />,
  'arrow-right': <ArrowRight />,
  'arrow-up': <ArrowUp />,
  'arrows': <Arrows />,

  'arrow-circle-left': <ArrowCircleLeft />,
  'arrow-circle-right': <ArrowCircleRight />,
  'arrow-circle-up': <ArrowCircleUp />,
  'arrow-circle-down': <ArrowCircleDown />,
  'arrow-right-to-bracket': <ArrowRightFromBrackek />,
  'arrow-to-bottom': <ArrowToBottom />,

  'asterisk': <Asterix />,
  'atom-alt': <AtomAlt />,
  'backward': <Backward />,
  'badge': <Badge />,
  'badge-percent': <BadgePercent />,
  'ban': <Ban />,
  'bars-staggered': <BarsStraggered />,
  'bars': <Bars />,
  'bell': <Bell />,
  'bells': <Bells />,
  'bezier-curve': <BezierCurve />,
  'book': <Book />,
  'bookmark': <Bookmark />,
  'bullhorn': <Bullhorn />,
  'bullseye': <Bullseye />,
  'bullseye-pointer': <BullseyePointer />,

  'chart-pie': <ChartPie />,
  'chart-simple': <ChartSimple />,
  'chart-user': <ChartUser />,
  'chart-waterfall': <ChartWaterfall />,
  'check': <Check />,
  'check-circle': <CheckCircle />,
  'chevron-down': <ChevronDown />,
  'chevron-left': <ChevronLeft />,
  'chevron-right': <ChevronRight />,
  'chevron-up': <ChevronUp />,
  'chevron-double-up': <ChevronDoubleUp />,
  'chevron-double-down': <ChevronDoubleDown />,
  'chevron-double-right': <ChevronDoubleRight />,
  'chevron-double-left': <ChevronDoubleLeft />,
  'chevron-circle-down': <ChevronCircleDown />,
  'caret-circle-down': <CaretCircleDown />,
  'caret-down': <CaretDown />,
  'caret-up': <CaretUp />,
  'circle': <Circle />,
  'circle-notch': <CircleNotch />,
  'circle-plus': <CirclePlus />,
  'clone': <Clone />,
  'clock': <Clock />,
  'cloud-upload': <CloudUpload />,
  'columns': <Columns />,
  'comment-alt': <CommentAlt />,
  'comments-alt': <CommentsAlt />,
  'comment': <Comment />,
  'comments': <Comments />,
  'comment-alt-plus': <CommentAltPlus />,
  'comment-alt-slash': <CommentAltSlash />,
  'comment-lines': <CommentLines />,
  'cross': <Cross />,
  'code-branch': <CodeBranch />,
  'cog': <Cog />,

  'dizzy': <Dizzy />,
  'download': <Download />,

  'edit': <Edit />,
  'empty-set': <EmptySet />,
  'equals': <Equals />,
  'engine-warning': <EngineWarning />,
  'envelope': <Envelope />,
  'ellipsis-h': <EllipsisH />,
  'ellipsis-v': <EllipsisV />,

  'exclamation': <Exclamation />,
  'exclamation-circle': <ExclamationCircle />,
  'exclamation-square': <ExclamationSquare />,
  'exclamation-triangle': <ExclamationTriangle />,
  'external-link': <ExternalLink />,
  'eye': <Eye />,
  'eye-slash': <EyeSlash />,
  'external-link-alt': <ExternalLinkAlt/>,

  'font': <Font />,
  'file-alt': <FileAlt />,
  'file-download': <FileDownload />,
  'file-import': <FileImport />,
  'filter': <Filter />,
  'flask': <Flask />,
  'flask-poison': <FlaskPoison />,
  'flask-potion': <FlaskPotion />,
  'folder': <Folder />,
  'folders': <Folders />,
  'folder-plus': <FolderPlus />,
  'folder-open': <FolderOpen />,
  'folder-tree': <FolderTree />,
  'forward': <Forward />,

  'gauge': <Gauge />,
  'gauge-simple': <GaugeSimple />,
  'gas-pump-slash': <GasPumpSlash />,
  'gas-pump': <GasPump />,
  'gift': <Gift />,
  'globe': <Globe />,

  'insta-share': <InstaShare />,
  'input-text': <InputText />,

  'handshake': <Handshake />,
  'hand-pointer': <HandPointer />,
  'hashtag': <Hashtag />,
  'headway': <Headway />,
  'heart': <Heart />,
  'horizontal-rule': <HorizontalRules />,
  'info': <Info />,
  'info-circle': <InfoCircle />,

  'link': <Link />,
  'list': <List />,
  'long-arrow-down': <LongArrowDown />,
  'long-arrow-right': <LongArrowRight />,
  'long-arrow-left': <LongArrowLeft />,
  'long-arrow-up': <LongArrowUp />,

  'map-marked-alt': <MapMarkedAlt />,
  'map-marker-alt': <MapMarkerAlt />,
  'mars': <Mars />,
  'minus': <Minus />,
  'mobile': <Mobile />,

  'paper-plane': <Paperplane />,
  'paperclip': <Paperclip />,
  'pause': <Pause />,
  'pause-circle': <PauseCircle />,
  'pen': <Pen />,
  'pennant': <Pennant />,
  'play': <Play />,
  'play-circle': <PlayCircle />,
  'plus': <Plus />,
  'power-off': <PowerOff />,
  'questionmark': <QuestionCircle />,
  'retweet': <Retweet />,

  'save': <Save />,
  'search': <Search />,
  'share-alt': <ShareAlt />,
  'shield-check': <ShieldCheck />,
  'sliders-h-square': <SliderHSquare />,
  'smile': <Smile />,
  'sort-down': <SortDown />,
  'sort-up': <SortUp />,
  'sparkles': <Sparkles />,
  'star': <Star />,
  'sticky-note': <StickyNote />,
  'sync': <Sync />,

  'tag': <Tag />,
  'tasks': <Tasks />,
  'telescope': <Telescope />,
  'thumbs-down': <ThumbsDown />,
  'thumbs-up': <ThumbsUp />,
  'times': <Times />,
  'times-circle': <TimesCircle />,
  'trash': <Trash />,
  'trash-alt': <TrashAlt />,
  'trash-list': <TrashList />,

  'unlink': <Unlink />,
  'user': <User />,
  'user-cirle': <UserCircle />,
  'user-graduate': <UserGraduate />,
  'user-cog': <UserCog />,
  'user-plus': <UserPlus />,
  'users': <Users />,
  'venus': <Venus />,
  'wrench': <Wrench />,
  undefined: <EllipsisH />
});
