import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const MaxWidth = ({ children, maxWidth, style, ...rest }) => {
  const maxWidthStyle = useMemo(
    () => ({
      position: 'relative',
      display: 'flex',
      width: 'fit-content',
      maxWidth,
      ...style
    }),
    [maxWidth, style]
  );

  return (
    <div style={maxWidthStyle} {...rest}>
      {children}
    </div>
  )
};

MaxWidth.displayName = 'MaxWidth';

MaxWidth.propTypes = {
  children: PropTypes.any.isRequired,
  maxWidth: PropTypes.number,
};

MaxWidth.defaultProps = {
  maxWidth: 'auto',
}

export default memo(MaxWidth);
