import React, { memo, useEffect, useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { nanoid } from 'nanoid';

import Button from '../Button2';
import Icon from '../Icon';
import Text from '../Text';

import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.scss';
import styles from './DatePicker.module.scss';

const addCustomClass = () => {
  const elements = document.querySelectorAll('.DateRangePicker');
  return elements && Array.from(elements).map(element => element.classList.add('kolkit_datePicker'))
}

const DatePicker = props => {
  const {
    onDatesChange,
    onApply,
    onCancel,
    applyLabel,
    cancelLabel,
    startDate,
    endDate,
    isOutsideRange,
    displayFormat,
    from,
    to,
    startDatePlaceholder,
    endDatePlaceholder,
    size,
    withAction,
  } = props;
  const [focusedInput, setFocusedInput] = useState(null);

  const refDate = useRef(null);
  const refInputs = useRef({
    startDate: nanoid(),
    endDate: nanoid()
  })

  useEffect(() => {
    addCustomClass()
  }, []);

  const handleFocusChange = useCallback(
    focusedInput => {
      setFocusedInput(focusedInput);
      if (focusedInput && refInputs.current) {
        const input = document.getElementById(refInputs.current[focusedInput]);
        if (input) {
          input.focus();
          input.setSelectionRange(0, 0);
        }
      }
    },
    [refInputs]
  );

  const cnDatePicker = cn(styles.dateWrapper, styles[size], `kolkit_date2_${size}`, {
    [styles.focus]: Boolean(focusedInput),
  });

  const handleClose = useCallback(
    async () => {
      if (onCancel) await onCancel();
      return refDate.current.onOutsideClick()
    },
    [refDate, onCancel]
  );

  const handleApply = useCallback(
    async () => {
      if (onApply) await onApply();
      return refDate.current.onOutsideClick();
    },
    [refDate, onApply]
  );

  const handleDatesChange = useCallback(
    (dates) => {
      onDatesChange(dates)
      // Reset focus after selecting the end date
      if (focusedInput === 'endDate') {
        setFocusedInput('startDate')
      }
    },
    [focusedInput, onDatesChange],
  )

  const renderBottomOptions = useCallback(
    () => withAction ? (
      <div className={styles.bottomOptions}>
        <Button
          label={cancelLabel}
          onClick={handleClose}
          theme="tertiary"
        />
        <Button
          label={applyLabel}
          onClick={handleApply}
        />
      </div>
    ) : null,
    [handleClose, handleApply, applyLabel, cancelLabel, withAction]
  );

  return (
    <div className={cnDatePicker}>
      <Text margin="0 4px 0 0" fontSize={14} className={styles.from}>
        {from}
      </Text>
      <DateRangePicker
        {...props}
        ref={refDate}
        id={nanoid()}
        startDateId={refInputs.current['startDate']}
        endDateId={refInputs.current['endDate']}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleDatesChange}
        onFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        firstDayOfWeek={1}
        isOutsideRange={isOutsideRange}
        startDatePlaceholderText={startDatePlaceholder}
        endDatePlaceholderText={endDatePlaceholder}
        displayFormat={displayFormat}
        hideKeyboardShortcutsPanel
        inputIconPosition="after"
        noBorder
        keepFocusOnInput
        keepOpenOnDateSelect={withAction}
        customArrowIcon={
          <Text margin="0 4px" fontSize={14} className={styles.to}>
            {to}
          </Text>
        }
        customInputIcon={
          <Icon
            label="caret-circle-down"
            theme="solid"
            fill="#0061AC"
            className={styles.icon}
          />
        }
        navPrev={
          <div className={styles.left}>
            <Icon label="arrow-left" theme="regular" isButton />
          </div>
        }
        navNext={
          <div className={styles.right}>
            <Icon label="arrow-right" theme="regular" isButton />
          </div>
        }
        renderCalendarInfo={renderBottomOptions}
      />
    </div>
  )
};

DatePicker.defaultProps = {
  cancelLabel: 'Cancel',
  applyLabel: 'Apply',
  withAction: true,
  from: 'from',
  to: 'to'
}

export default memo(DatePicker);
