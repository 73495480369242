import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import Button2 from '../Button2';
import styles from './Modal2.module.scss';

const ModalAction2 = (props) => {
  const { primaryTitle, primaryAction, primaryDisabled, primaryTooltip, primaryDataId, secondaryTitle, secondaryAction, secondaryDisabled, secondaryTooltip, secondaryDataId } = props;

  const displayPrimaryButton = useMemo(
    () => primaryTitle && (
      <Button2
        onClick={primaryAction}
        disabled={primaryDisabled}
        tooltip={primaryTooltip}
        dataId={primaryDataId}
      >
        {primaryTitle}
      </Button2>
    ),
    [primaryTitle, primaryAction, primaryDisabled, primaryTooltip, primaryDataId]
  );

  const displaySecondaryButton = useMemo(
    () => secondaryTitle && (
      <Button2
        theme="tertiary"
        size="big"
        onClick={secondaryAction}
        disabled={secondaryDisabled}
        tooltip={secondaryTooltip}
        dataId={secondaryDataId}
      >
        {secondaryTitle}
      </Button2>
    ),
    [secondaryTitle, secondaryAction, secondaryDisabled, secondaryTooltip, secondaryDataId]
  );

  return (
    <div className={styles.modalAction} {...props}>
      {displaySecondaryButton}
      {displayPrimaryButton}
    </div>
  )
};

ModalAction2.propTypes = {
  primaryTitle: PropTypes.string,
  secondaryTitle: PropTypes.string,
  primaryAction: PropTypes.func,
  primaryTooltip: PropTypes.string,
  primaryDataId: PropTypes.string,
  secondaryAction: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  secondaryDisabled: PropTypes.bool,
  secondaryTooltip: PropTypes.string,
  secondaryDataId: PropTypes.string
}

ModalAction2.defaultProps = {
  primaryTitle: null,
  secondaryTitle: null,
  primaryAction: null,
  primaryTooltip: null,
  primaryDataId: null,
  secondaryAction: null,
  primaryDisabled: false,
  secondaryDisabled: false,
  secondaryTooltip: null,
  secondaryDataId: null
}

export default memo(ModalAction2);
