import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import styles from './Accordion.module.scss';

import Icon from '../Icon';

const Accordion = ({
  name,
  dataId,
  expanded,
  onClick,
  summary,
  className,
  children
}) => {
  const handleClick = (event, isExpanded) => {
    if (onClick) onClick({ name, isExpanded, event });
  };

  const cnAccordion = cn(styles.root, className);

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleClick}
      className={cnAccordion}
    >
      <AccordionSummary
        expandIcon={
          <Icon label="caret-circle-down" theme="solid" fill="#0061AC" />
        }
        aria-controls={`accordion-${name}`}
        id={`accordion-${name}`}
        className={styles.summary}
        data-id={dataId}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  name: propTypes.string.isRequired,
  dataId: propTypes.string,
  expanded: propTypes.bool,
  onClick: propTypes.func,
  summary: propTypes.node,
  className: propTypes.string,
  children: propTypes.node
};
Accordion.defaultProps = {
  dataId: null,
  expanded: false,
  onClick: null,
  summary: null,
  className: null,
  children: null
};
Accordion.displayName = 'Accordion';

export default Accordion;
