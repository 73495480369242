import React, { memo, useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { StringOrReact } from '../utils/propTypes';
import Icon from '../Icon';

import styles from './SelectTag.module.scss';

const Tag = props => {
  const { icon, label, value, onClick, onClickDelete, className, disabled, theme, dataId } = props;

  const handleClick = useCallback(
    () => onClick && onClick(value),
    [onClick, value]
  );

  const handleClickDelete = useCallback(
    () => onClickDelete && onClickDelete(value),
    [onClickDelete, value]
  );

  const cnLi = cn(styles.tag, styles[theme], className, {
    [styles.disabled]: disabled || (!handleClick && !handleClickDelete),
    [styles.noDelete]: !handleClickDelete,
    [styles.clickable]: !!handleClick
  });

  const cnLabel = cn({
    'no-click': !handleClick
  })

  return (
    <li className={cnLi}>
      <Icon label={icon} />
      <span className={cnLabel} onClick={handleClick} disabled={disabled} data-id={dataId}>
        {label}
      </span>
      {!disabled && onClickDelete && (
        <Icon
          isButton
          label="times"
          onClick={handleClickDelete}
          fill="#fff"
          size="small"
          dataId={dataId ? `${dataId}-icon` : null}
        />
      )}
    </li>
  );
};

Tag.displayName = 'Tag';

Tag.propTypes = {
  icon: StringOrReact,
  label: StringOrReact,
  value: StringOrReact,
  onClick: PropTypes.func,
  onClickDelete: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dataId: PropTypes.string,
  theme: PropTypes.string
};

Tag.defaultProps = {
  icon: null,
  label: '',
  value: '',
  onClick: null,
  onClickDelete: null,
  className: '',
  disabled: false,
  theme: 'navy'
};

export default memo(Tag);
