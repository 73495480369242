import { createElement, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Text.module.scss';

const Text = ({
  tag,
  className,
  children,
  fontSize,
  fontWeight,
  margin,
  size,
  whiteSpace,
  resetMargin,
  fontTitle,
  style,
  ...rest
}) => {
  const cnText = cn(styles.text, className, styles[tag], {
    [styles[size]]: !tag.startsWith('h'),
    [styles.reset]: resetMargin,
    [styles.fontTitle]: fontTitle
  });

  const styleText = useMemo(
    () => fontSize && ({
      fontSize,
      lineHeight: `${1.5 * fontSize}px`
    }),
    [fontSize]
  );

  const styleWeight = useMemo(
    () => fontWeight && ({ fontWeight }),
    [fontWeight]
  );

  const styleMargin = useMemo(
    () => margin && ({
      margin
    }),
    [margin]
  );

  const styleWhiteSpace = useMemo(
    () => whiteSpace && ({
      whiteSpace
    }),
    [whiteSpace]
  );

  return createElement(
    tag, {
      ...rest,
      className: cnText,
      style: {
        ...styleText,
        ...styleMargin,
        ...styleWhiteSpace,
        ...styleWeight,
        ...style
      },
      children
    }
  )
};

Text.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  whiteSpace: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small', 'big']),
  children: PropTypes.any.isRequired,
  fontTitle: PropTypes.bool,
  resetMargin: PropTypes.bool
}

Text.defaultProps = {
  tag: 'p',
  size: 'default',
  fontSize: null,
  whiteSpace: null,
  margin: null,
  fontWeight: null,
  className: null,
  fontTitle: false,
  resetMargin: false
}

export default memo(Text);
