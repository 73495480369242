import React, { Children, cloneElement, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from '../Checkbox';

import styles from './Checkboxes.module.scss';

const Checkboxes = ({
  checkedOption,
  halfchecked,
  className,
  disabled,
  itemId,
  label,
  name,
  onChange,
  required,
  dataset,
  direction,
  theme,
  ...rest
}) => {
  const cnCheckboxes = cn(styles.checkboxes, styles[direction], className, {
    [styles.disabled]: disabled
  });

  const handleChecked = useCallback(
    value => {
      const getValue = value && value.toString().toLowerCase();
      return checkedOption?.includes(getValue);
    },
    [checkedOption]
  );

  const handleChange = useCallback(
    e => {
      let newSelected;
      const isChecked = handleChecked(e.currentTarget.value);
      !isChecked
        ? newSelected = [...checkedOption, e.currentTarget.value]
        : newSelected = checkedOption?.filter(s => s !== e.currentTarget.value);

      return onChange(newSelected);
    },
    [checkedOption, handleChecked, onChange]
  );

  return (
    <div className={cnCheckboxes} {...rest}>
      {dataset && Children.toArray(dataset?.map((option, index) => {
        const value = option.value ? option.value : option.label;
        return cloneElement(
          <Checkbox
            id={`${value}-${index}-${name}`}
            name={name}
            label={option.label}
            value={value}
            checked={handleChecked(value)}
            halfchecked={halfchecked}
            disabled={option.disabled}
            onChange={handleChange}
            required={required}
            theme={theme}
            dataId={option.dataId}
          />
        );
      }))}
    </div>
  );
};

Checkboxes.displayName = 'Checkboxes';

Checkboxes.propTypes = {
  checked: PropTypes.bool,
  halfchecked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  itemId: PropTypes.any,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.bool
  ]),
  name: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checkedOption: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataset: PropTypes.arrayOf(PropTypes.any).isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical'])
};

Checkboxes.defaultProps = {
  checked: false,
  halfchecked: false,
  className: undefined,
  disabled: false,
  itemId: '',
  label: null,
  required: false,
  onChange: () => {},
  direction: 'horizontal'
};

export default memo(Checkboxes);
