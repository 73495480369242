/* eslint-disable react/prop-types */
import React, { memo, useState, useCallback, useRef } from 'react';
import cn from 'classnames';

import Icon from '../Icon';
import Input from '../Input';
import Tags from './Tags';

import styles from './InputTag.module.scss';

const InputTag = ({
  autoHashtag, onlyHashtagOrAt, highlightWords, disabled, displaySearchButton,
  selectable, editable, autoFocus, autoAt, displayPlaceholder, tags, onChange,
  placeholder, className, noBorder, dataId,
}) => {
  const [state, setState] = useState({
    searchValue: '',
    selectedTagIndex: null,
    onKeyDownSelectedTagActive: true
  });

  const myRef = useRef(null);

  const cleanInputValue = useCallback(
    inputValue => {
      const prefix = ['+', '-', '@', '#'];
      const wrapper = ['"'];

      const value = inputValue.trim();

      if (value === '') return value;

      const prefixIndex = prefix.indexOf(value[0]);
      const wrapperIndex = wrapper.indexOf(value[0]);

      if (prefixIndex !== -1) return `${prefix[prefixIndex]}${value.substr(1, value.length - 1).trim()}`;
      if (wrapperIndex !== -1) {
        if (value[0] === value[value.length - 1]) return `${wrapper[wrapperIndex]}${value.substr(1, value.length - 2).trim()}${wrapper[wrapperIndex]}`;
        return `${wrapper[wrapperIndex]}${value.substr(1, value.length - 1).trim()}${wrapper[wrapperIndex]}`;
      }
      if (autoHashtag) return `#${value}`;
      if (autoAt) return `@${value}`;
      return value;
    },
    [autoAt, autoHashtag]
  );

  const addTag = useCallback(
    () => {
      const tag = cleanInputValue(state.searchValue);

      // clear search value
      setState(prevState => ({ ...prevState, searchValue: '' }));

      // avoid empty tags
      if (tag === '') return null;

      // prevent from adding tags who are not prefixed by '@' or '#' if onlyHashtagOrAt props
      if (onlyHashtagOrAt && tag[0] !== '@' && tag[0] !== '#') return null;
      const newTags = [...tags, tag];
      onChange && onChange({
        tags: newTags,
        tag,
        action: 'addition'
      });
    },
    [cleanInputValue, onChange, onlyHashtagOrAt, state.searchValue, tags]
  );

  const updateSearchValue = useCallback(
    ({ value: searchValue }) => {
      setState(prevState => ({
        ...prevState,
        selectedTagIndex: null
      }));
      if (searchValue[searchValue?.length - 1] === ',') {
        addTag();
      } else {
        setState(prevState => ({ ...prevState, searchValue }));
      }
    },
    [addTag]
  );

  const deleteTag = useCallback(
    tagIndex => {
      const newTags = tags.filter((_, index) => index !== tagIndex);
      const deletedTag = tags[tagIndex];

      onChange && onChange({
        tags: newTags,
        tag: deletedTag,
        action: 'deletion'
      });
    },
    [onChange, tags]
  );

  const onKeyPress = useCallback(
    ({ key, event }) => {
      // Force focus on tab if tags
      if (key === 'Tab') {
        if (state.searchValue.length > 0) event.preventDefault();
        addTag();
        return null;
      }

      if (key === 'ArrowLeft' &&
        selectable && state.searchValue === '' &&
        state.selectedTagIndex === null) {
        setState(prevState => ({
          ...prevState,
          onKeyDownSelectedTagActive: false,
          selectedTagIndex: tags?.length - 1
        }));
      }

      if (key === 'ArrowRight' &&
        selectable && state.searchValue === '' &&
        state.selectedTagIndex === null
      ) {
        setState(prevState => ({
          ...prevState,
          onKeyDownSelectedTagActive: false,
          selectedTagIndex: 0
        }));
      }

      if (key === 'Backspace' &&
        state.searchValue === '' &&
        tags?.length > 0 &&
        (state.selectedTagIndex === null ||
          state.selectedTagIndex < 0 ||
          state.selectedTagIndex >= tags?.length)
      ) {
        deleteTag(tags?.length - 1)
      }
    },
    [addTag, deleteTag, selectable, state.searchValue, state.selectedTagIndex, tags.length]
  );

  const activateOnKeyDownSelectedTag = useCallback(
    () => {
      setState(prevState => ({
        ...prevState,
        onKeyDownSelectedTagActive: true
      }));
    },
    []
  );

  const handleDeleteTag = index => () => deleteTag(index);

  const handleUpdateTag = index => value => updateTag(value, index);

  const updateTag = useCallback(
    (value, tagIndex) => {
      const newTag = cleanInputValue(value);
      const newTags = tags.map((tag, index) => (index === tagIndex ? newTag : tag));
      const updatedTag = tags[tagIndex];

      onChange && onChange({
        tags: newTags,
        tag: updatedTag,
        action: 'edition'
      });
    },
    [cleanInputValue, tags, onChange]
  );

  const cnContent = cn(styles.content, {
    [styles.noBorder]: noBorder
  })

  const renderSearchBar = () => {
    return (
      <div className={styles.container}>
        <div className={cnContent}>
          {tags?.map((tag, index) => (
            <Tags
              value={tag}
              key={index}
              index={index}
              editable={editable}
              selectable={selectable}
              onArrowKey={onArrowKeySelectedTag}
              onChangeSelectedTag={onChangeSelectedTag}
              isSelected={state.selectedTagIndex === null ? null : (selectable && state.selectedTagIndex === index)}
              disabled={disabled}
              autoFocus={autoFocus}
              onlyHashtagOrAt={onlyHashtagOrAt}
              highlightWords={highlightWords || []}
              deleteTag={handleDeleteTag(index)}
              updateTag={handleUpdateTag(index)}
              myRef={myRef}
              onKeyDownSelectedTagActive={state.onKeyDownSelectedTagActive}
              activateOnKeyDownSelectedTag={activateOnKeyDownSelectedTag}
            />
          ))}
          <Input
            resetStyle
            className={className}
            autoFocus={autoFocus}
            value={state.searchValue}
            onChange={updateSearchValue}
            disabled={disabled}
            onEnterPress={addTag}
            onBlur={addTag}
            onKeyPress={onKeyPress}
            placeholder={(displayPlaceholder && tags?.length === 0 && state.searchValue === '') ? placeholder : undefined}
            dataId={dataId}
          />
        </div>
        {displaySearchButton && (
          <Icon label="search" onClick={addTag} disabled={disabled} />
        )}
      </div>
    )
  };

  const onChangeSelectedTag = ({ index, value: isSelected }) => {
    setState(prevState => ({
      ...prevState,
      selectedTagIndex: isSelected ? index : null
    }));
  };

  const onArrowKeySelectedTag = direction => {
    if (direction === 'left') {
      setState(prevState => ({
        ...prevState,
        selectedTagIndex: state.selectedTagIndex === 0
          ? tags?.length - 1
          : prevState.selectedTagIndex - 1
      }));
    }

    if (direction === 'right') {
      setState(prevState => ({
        ...prevState,
        selectedTagIndex: state.selectedTagIndex === tags?.length - 1
          ? 0
          : prevState.selectedTagIndex - 1
      }));
    }
  };

  const cnInputTag = cn(styles.inputTag, className);

  return (
    <div className={cnInputTag} ref={myRef}>
      {renderSearchBar()}
    </div>
  )
}

InputTag.displayName = 'InputTag';

InputTag.defaultProps = {
  dataId: null,
  noBorder: false
}

export default memo(InputTag);
