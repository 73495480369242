/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Picker } from 'emoji-mart';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Icon from '../Icon';

import './emoji.scss';
import styles from './InputMessage.module.scss';

let timeout = null;
let caretTimeout = null;

const insertAt = (target, newString, pos) => {
  if (typeof (pos) === 'undefined') pos = 0;
  if (typeof (newString) === 'undefined') newString = '';

  return target.slice(0, pos) + newString + target.slice(pos);
};

const InputMessage = ({
  onChange,
  onSubmit,
  value,
  className,
  placeholder,
  rows,
  wrap,
  height,
  pickerStyle,
  forceFocus,
  onFocus,
  onBlur,
  dataId,
  ...rest
}) => {
  const ref = useRef(null);
  const [on, setOn] = useState(false);
  const [focus, setFocus] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
      clearTimeout(caretTimeout);
    }
  }, [])

  useEffect(() => {
    if (forceFocus) setFocus(true);
  }, [forceFocus])

  const inputStyle = useMemo(
    () => {
      return {
        lineHeight: 1.5,
        minHeight: !focus ? '40px' : '160px',
        borderRadius: !focus ? '30px' : '12px'
      }
    },
    [focus]
  );

  const handleFocus = useCallback(
    () => {
      setFocus(true);
      onFocus()
    },
    [onFocus]
  );

  const handleChangeEmoji = useCallback(
    emoji => {
      const getValue = insertAt(value, emoji?.native, cursorPosition);
      onChange({ value: getValue });
      setOn(false);
      const currentRef = ref?.current;
      currentRef.focus();
      caretTimeout = setTimeout(
        () => currentRef.setSelectionRange(cursorPosition + 1, cursorPosition + 1),
        100
      );
      return true;
    },
    [onChange, value, cursorPosition]
  );

  const handleChange = useCallback(
    e => {
      onChange({
        value: e.target.value
      })
    },
    [onChange]
  );

  const handleClickInput = useCallback(
    e => {
      e.preventDefault();
      setCursorPosition(e.target.selectionStart);
    },
    []
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (value) {
          onSubmit({
            value,
            source: e.key
          });
        }
        if (ref.current) return ref.current.blur();
      }
    },
    [onSubmit, value, ref]
  )

  const togglePicker = useCallback(
    () => setOn(prevState => !prevState),
    [setOn]
  );

  const forceOffPicker = useCallback(
    async () => {
      await setFocus(false);
      timeout = setTimeout(
        () => setOn(false),
        210
      )
    },
    [setOn, setFocus]
  );

  const picker = useMemo(
    () => on ? (
      <Picker
        {...rest}
        style={pickerStyle}
        native
        onSelect={handleChangeEmoji}
      />
    ) : null,
    [on, handleChangeEmoji]
  );

  const cnInput = cn(styles.inputMessage, className, {
    [styles.fixed]: Boolean(height)
  });

  return (
    <ClickAwayListener onClickAway={forceOffPicker}>
      <div className={cnInput}>
        <div className={styles.picker}>
          {picker}
        </div>
        <Icon
          label="smile"
          className={styles.icon}
          onClick={togglePicker}
          isButton
        />
        <textarea
          {...rest}
          ref={ref}
          className={styles.input}
          onClick={handleClickInput}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={onBlur}
          onKeyPress={e => handleKeyPress(e)}
          placeholder={placeholder}
          style={inputStyle}
          rows={!height ? '1' : rows}
          wrap={wrap}
          value={value}
          data-id={dataId}
        />
      </div>
    </ClickAwayListener>
  )
};

InputMessage.displayName = 'InputMessage';

InputMessage.propTypes = {
  dataId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  forceFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

InputMessage.defaultProps = {
  dataId: null,
  onChange: () => {},
  onSubmit: () => {},
  className: null,
  placeholder: null,
  forceFocus: false,
  onFocus: () => {},
  onBlur: () => {}
}

export default memo(InputMessage);
